import React from 'react';
import axios from 'axios';
import './App.css';

const BASEURL = 'http://13.233.161.29:3563';

function App() {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [selectedEffect, setSelectedEffect] = React.useState('Paprika');
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [data, setData] = React.useState({
    success: false,
    content: {
      face_src: '',
      avatar_src: '',
    },
  });

  const handleFilePicker = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleEffChange = (e) => {
    setSelectedEffect(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg('');
    setLoading(true);

    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('name', 'webdemotest');
    formData.append('effect', selectedEffect);

    try {
      console.log(selectedFile);
      const response = await axios({
        method: 'post',
        url: `${BASEURL}/user/upload`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log('Response: ', response);
      setData({ success: true, content: response.data });
    } catch (err) {
      console.error(err.message);
      setErrorMsg('Failed to process image, try again. Use another image, if possible.');
    }
    setLoading(false);
  };

  return (
    <>
      <div className="container">
        {data.success && data.content?.face_src ? (
          <div className="image-container">
            <img className="image-face" src={BASEURL + data.content.face_src} alt="avatar head" />
            <img className="image-body" src={BASEURL + data.content.avatar_src} alt="avatar full" />
          </div>
        ) : (
          <>
            <h4>Avatar Generator</h4>
            {!loading ? (
              <form className="upload-form" onSubmit={handleSubmit}>
                <label htmlFor="avatar">Select an image: </label>
                <input id="avatar" name="avatar" accept="image/*" type="file" onChange={handleFilePicker} />
                <br />
                <label htmlFor="effect">Choose an effect:</label>
                <select name="effect" id="effect" value={selectedEffect} onChange={handleEffChange}>
                  <option value="none">None</option>
                  <option value="Paprika">Effect1</option>
                  <option value="Hayao">Effect2</option>
                  <option value="Hosoda">Effect3</option>
                  <option value="Shinkai">Effect4</option>
                </select>
                <br />
                <button type="submit">Submit</button>
                <p className="error-text">{errorMsg}</p>
              </form>
            ) : (
              <p>Loading ...</p>
            )}
          </>
        )}
      </div>
      <p className="footer">Designed and developed by Bluestream.io</p>
    </>
  );
}

export default App;
